import Footer from "components/footer/Footer";
import PropertyInfo from "components/ui/property-info/PropertyInfo";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  useGetDashboardBannerQuery,
  useGetDashboardItemsQuery,
  useMaticResponseMutation,
} from "pages/dashboard/home/dashboardService";
import useDesktopChecker from "hooks/useDesktopChecker";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUserId,
  getCurrentUserName,
  getVersion,
  getCurrentTooltipIndex,
  setCurrentTooltipIndex,
} from "features/authSlice";
import "./shared-dashboard.scss";
import {
  userVersionFeatureMap,
  UserVersion,
  UserFeature,
} from "utilities/UserversionFeatures";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { MyDashboard, MyDocuments } from "utilities/Constant";
import { Button } from "components/ui/button/Button";
import Advertisement from "components/Advertisement/Advertisement";
import { Loader } from "components/Loader/Loader";
import NoLoanInfo from "components/ui/no-loan-info-dashboard/NoLoanInfo";
import GuideToolTip from "components/ui/guideToolTip/GuideToolTip";
import { tooltipIndexValues } from "pages/dashboard/home/Dashboard";

export default function SharedDashboard() {
  const version = useSelector(getVersion);
  const versionFeatures =
    userVersionFeatureMap[version.toUpperCase() as UserVersion];
  const navigate = useNavigate();
  if (!versionFeatures?.includes(UserFeature.SHOW_DASHBOARD)) {
    navigate("/");
  }
  const isDashboard = location.pathname === "/dashboard";
  const userId = useSelector(getCurrentUserId);
  const { data } = useGetDashboardItemsQuery(userId);
  const loanstatus = data?.loanStatus.status == "Funded";
  const { data: dashboardAdValue } = useGetDashboardBannerQuery(userId);
  const userName = useSelector(getCurrentUserName);
  const firstName = userName.split(" ")[0];
  const isDesktop = useDesktopChecker();
  const [maticResponse, { isLoading }] = useMaticResponseMutation();
  const loadMaticLandingURL = async () => {
    const maticRedirectURL = await maticResponse(userId).unwrap();
    window.open(maticRedirectURL, "_blank");
  };
  const isDocument = location.pathname === "/dashboard/documents";
  const currentTooltipIndex = useSelector(getCurrentTooltipIndex);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const hasEmptyLoanDetails = () => {
    if (
      !data?.propertyInfo.street &&
      !data?.propertyInfo.city &&
      !data?.propertyInfo.state &&
      !data?.propertyInfo.zipCode &&
      !data?.propertyInfo.loanNumber &&
      !data?.propertyInfo.loanAmount &&
      !data?.propertyInfo.loanType
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isDocumentsActive =
    pathname === "/dashboard/documents" ||
    currentTooltipIndex === tooltipIndexValues.documents;

  const highlightClass =
    currentTooltipIndex === tooltipIndexValues.documents ? "highlight" : "";

  return (
    <div className="sharedDashboardWrapper">
      {!isDesktop && (
        <div className="toggleTabsWrapper">
          <div className="toggleTabsWrapper_toggleTabs">
            <NavLink
              to="/dashboard"
              className="toggleTabsWrapper_toggleTabs_tab"
              end
            >
              MY DASHBOARD
            </NavLink>
            <NavLink
              to="/dashboard/documents"
              className={`toggleTabsWrapper_toggleTabs_tab ${
                isDocumentsActive ? "active" : ""
              } ${highlightClass} ${
                currentTooltipIndex === tooltipIndexValues.documents
                  ? "no-border"
                  : ""
              }`}
            >
              MY DOCUMENTS
            </NavLink>
            {currentTooltipIndex === tooltipIndexValues.documents && (
              <div className="tooltip3">
                <GuideToolTip
                  contentHeading="My Documents"
                  tooltipNo="3 of 3"
                  content="Navigate here to view your documents."
                  buttonName="Explore your dashboard"
                  buttonOnClick={() => {
                    dispatch(
                      setCurrentTooltipIndex(tooltipIndexValues.completed)
                    );
                  }}
                  cancelClick={() => {
                    dispatch(
                      setCurrentTooltipIndex(tooltipIndexValues.completed)
                    );
                  }}
                />
              </div>
            )}
            {currentTooltipIndex !== tooltipIndexValues.initial &&
              currentTooltipIndex !== tooltipIndexValues.completed && (
                <div className="transparent-overlay"></div>
              )}
          </div>
          <div className="toggleTabsWrapper_line"></div>
        </div>
      )}
      <div className="shared-dashboard">
        <div
          className={
            isDocument
              ? "shared-dashboard__document-container"
              : "shared-dashboard__container"
          }
        >
          <div className="shared-dashboard__container__header">
            <div className="shared-dashboard__container__header--icon">
              {isDashboard ? (
                <FontAwesomeIcon
                  icon={solid("table-columns")}
                  className="table-icon"
                />
              ) : (
                <FontAwesomeIcon
                  icon={solid("file-lines")}
                  className="table-icon"
                />
              )}
            </div>
            <p className="shared-dashboard__container__header--text">
              {isDashboard ? MyDashboard : MyDocuments}
            </p>
          </div>
          {hasEmptyLoanDetails() ? (
            <NoLoanInfo />
          ) : (
            <PropertyInfo
              street={data?.propertyInfo.street}
              city={data?.propertyInfo.city}
              state={data?.propertyInfo.state}
              zipCode={data?.propertyInfo.zipCode}
              loanNumber={data?.propertyInfo.loanNumber}
              loanAmount={data?.propertyInfo.loanAmount}
              loanType={data?.propertyInfo.loanType}
            />
          )}
          <Outlet />
        </div>
        {isDashboard && dashboardAdValue != null && !loanstatus && (
          <>
            <Advertisement
              image={dashboardAdValue.image}
              logo={dashboardAdValue.logo}
              imageText={dashboardAdValue.imageText}
              text={
                <div className="dashboard-insurance__text">
                  <p className="dashboard-insurance__text--text1">
                    {"Hi" + " " + firstName + "!"}
                  </p>
                  <p className="dashboard-insurance__text--text2">
                    {dashboardAdValue.description1}
                  </p>
                </div>
              }
              subtext={
                <p className="dashboard-insurance__subtext">
                  {dashboardAdValue.description2}
                </p>
              }
              footer={
                <Button
                  className="dashboard-insurance__btn"
                  type="squared"
                  variant="primary"
                  title="Learn More"
                  onClick={loadMaticLandingURL}
                />
              }
            />
            {isLoading && (
              <Loader
                header={
                  isDesktop ? (
                    <>
                      We’re transferring you to NAF Insurance powered by Matic
                    </>
                  ) : (
                    <>
                      We’re transferring you to <br />
                      NAF Insurance powered by Matic
                    </>
                  )
                }
                message1="Bundle Home and Auto coverage to unlock additional Savings"
                message2="Please do not refresh or click Back in your browser"
              />
            )}
          </>
        )}
        <Footer />
      </div>
    </div>
  );
}
