import { Button } from "components/ui/button/Button";
import { useState } from "react";
import {
  useGetWelcomePathsQuery,
  useCreateApplicationMutation,
  CreateApplicationTemplateRequest,
} from "pages/welcome/welcomePathService";
import { LoadingOverlay } from "components/ui/loading-spinners";
import { useNavigate, useLocation } from "react-router-dom";
import "./welcome.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurrentUserId,
  setApplicationId,
  setLogout,
  getCurrentUserApplicationId,
} from "features/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import Footer from "components/footer/Footer";
import { welcomeSubtext } from "utilities/Constant";

export default function WelcomeMultiPath() {
  const {
    data: optionsData,
    isError: isGetWelcomePathError,
    isLoading,
    error: getWelcomePathError,
  } = useGetWelcomePathsQuery();
  const [
    CreateApplication,
    {
      data,
      isSuccess,
      isError: isCreateApplicationError,
      isLoading: createApplicationLoading,
      error: createApplicationError,
    },
  ] = useCreateApplicationMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState<{
    id: number;
    path: string;
  }>({ id: 0, path: "" });
  const userId = useSelector(getCurrentUserId);
  const applicationId = useSelector(getCurrentUserApplicationId);
  const dispatch = useDispatch();
  const handleClick = (id: number, path: string) => {
    setSelectedOption({ id, path });
  };
  const handleOnSubmit = async () => {
    const RequestBody: CreateApplicationTemplateRequest = {
      userId: userId,
      pathType: selectedOption.path,
    };
    await CreateApplication(RequestBody);
  };
  if (applicationId) {
    navigate("/application", { replace: true });
  } else if (isSuccess && data) {
    dispatch(setApplicationId(data));
    navigate("/application", { replace: true });
  } else if (isGetWelcomePathError || isCreateApplicationError) {
    if (
      (typeof getWelcomePathError === "object" &&
        "status" in getWelcomePathError &&
        getWelcomePathError.status === 401) ||
      (typeof createApplicationError === "object" &&
        "status" in createApplicationError &&
        createApplicationError.status === 401)
    ) {
      dispatch(setLogout());
      navigate("/", { replace: true, state: { path: location.pathname } });
    }
    // should add code for other errors
  }

  if (isLoading || createApplicationLoading) return <LoadingOverlay />;

  return (
    <div className="welcome-page">
      <div className="welcome-container">
        <span className="welcome-container_title">Easy. Secure. Reliable.</span>
        <div className="welcome-container_description">
          {welcomeSubtext}
        </div>
        <div className="welcome-container_options-header">
          How would you like to continue?
        </div>
        <div className="options-container">
          {optionsData?.map((item) => (
            <div
              key={item.id}
              className={
                selectedOption.id == item.id
                  ? "option-template option-template_active"
                  : "option-template"
              }
              onClick={() => handleClick(item.id, item.path)}
            >
              <span className={`option-template_title 
                ${item.id === 1 ? "item1-title" : "item2-title"}`}>
                  {item.title}
              </span>
              <div className={`option-template_img ${
                  item.id === 1 ? "item1-image" : "item2-image"
                }`}>
                <img src={item.image} alt="path-choice-image"/>
              </div>
              <span
                className={`option-template_description ${
                  item.id === 1 ? "item1-description" : "item2-description"
                }`}
              >
                {item.description}
              </span>
              <div className={`option-template__container ${
                item.id === 1 ? "item1-container" : "item2-container"
              }`}>
                <span className="option-template_estimation-time">
                  <FontAwesomeIcon
                    icon={regular("stopwatch")}
                    className="option-template_icon"
                  />
                </span>
                <span className="option-template__wrapper">
                  <p className="option-template_estimation-time--number">
                    {item.timeDescription}
                  </p>
                  <p className="option-template_estimation-time--mins">mins</p>
                </span>
              </div>
            </div>
          ))}
        </div>
        <Button
          title="Continue"
          size="large"
          disabled={!selectedOption.id}
          onClick={() => void handleOnSubmit()}
        />
      </div>
      <Footer />
    </div>
  );
}
