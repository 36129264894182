import useDesktopChecker from "hooks/useDesktopChecker";
import "./dropDownMenu.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";

export interface DropDownMenuProps {
  onClickPreference?: () => void;
  onClickLogout?: () => void;
  onShow?: boolean;
  showMyLoans: boolean;
  showNavbar: () => void;
  showPreferences: boolean;
}
const DropDownMenu = ({ onClickPreference, onClickLogout, onShow, showMyLoans, showNavbar, showPreferences }: DropDownMenuProps) => {
  const isDesktop = useDesktopChecker();
  const progress = location.pathname === "/application";
  const isHideMyApp = location.pathname === "/my-loans" || location.pathname === "/path";
  const navigate = useNavigate();
  const onClickMyLoans = () => {
    showNavbar();
    navigate("/my-loans");
  };
  return (
    <div className="dropDownMenu">
      <div className={`popup ${onShow ? "popup--show" : "popup--hide"}`}>
        <div className="popup__wrapper">
          {progress && <div className="popup__wrapper--content">Your progress is automatically saved!</div>}
          {!isDesktop && showMyLoans && !isHideMyApp && (
            <div className="popup__wrapper__top-buttons">
              <span className="" onClick={onClickMyLoans}>
                My Loans
              </span>
            </div>
          )}
          {(progress || !isDesktop) && <div className="popup__wrapper--line"></div>}
          <div className="popup__wrapper__buttons">
            {showPreferences && (
              <span className="popup__wrapper__buttons__preferences" onClick={onClickPreference}>
                Preferences
              </span>
            )}
            <span className="popup__wrapper__buttons__logout" onClick={onClickLogout}>
              <span>Logout</span>
              <FontAwesomeIcon icon={solid("right-from-bracket")} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropDownMenu;
