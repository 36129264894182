import WelcomePopup from "pages/dashboard-popup/welcome-popUp/WelcomePopUp";
import "./dashboard-popup.scss";
import PalPopup from "./pal-popup/PalPopup";
import { useSelector } from "react-redux";
import { getHasPAL } from "features/authSlice";
interface DashboardPopUpProps {
  readonly onClose: () => void;
  readonly userName?: string;
  readonly isPalEnabled: boolean;
}
export default function DashboardPopUp({ onClose, userName, isPalEnabled }: DashboardPopUpProps) {
  const isPAL = useSelector(getHasPAL);
  return (
    <div className="pop-up">
      <div className="pop-up__content">
        <div className="pop-up__content1">{(isPAL && isPalEnabled) ? <PalPopup onClose={onClose} /> : <WelcomePopup onClose={onClose} userName={userName} />}</div>
      </div>
    </div>
  );
}
